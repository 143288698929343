import React from "react";
import Tile from "../../components/Tile/Tile";
import develop1 from "../../data/assets/responsible-investing.webp";
import develop2 from "../../data/assets/guardbonds.webp";
import develop3 from "../../data/assets/advisher.webp";
import develop4 from "../../data/assets/tuf-site.svg";
import develop5 from "../../data/assets/codepen-logo.svg";
import develop6 from "../../data/assets/github-logo.svg";
import "./Coding.scss";

export default function Coding() {
  return (
    <section className="coding">
      <h2 className="coding__title">
        This collection of coding projects uses React, PHP, and Javascript to cover a variety of subjects, from
        microsites to webpage recreations.
      </h2>

      <div className="coding__container">
        
        <Tile
          className="coding__tile"
          link="https://www.guardiancapital.com/institutional-investment-management/responsible-investing/"
          source={develop1}
        />
        <Tile
          className="coding__tile"
          link="https://www.guardiancapital.com/investmentsolutions/guardbonds/"
          source={develop2}
        />
        <Tile
          className="coding__tile"
          link="https://www.guardiancapital.com/investmentsolutions/advisher/"
          source={develop3}
        />
        <Tile
          className="coding__tile"
          link="https://www.teamultraforce.com"
          source={develop4}
        />
        <Tile
          className="coding__tile"
          link="https://codepen.io/mcgregor-studio"
          source={develop5}
        />
        <Tile
          className="coding__tile"
          link="https://github.com/mcgregor-studio"
          source={develop6}
        />
      </div>
    </section>
  );
}
